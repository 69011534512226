<template>
	<div class="gg-container">


		<div class="search-container">

			<!-- <div class="search-container-fn-input">
				<label>权限名称/路由：</label>
				<el-input size="small" placeholder="请输入科室名称" prefix-icon="el-icon-search" v-model="searchParams.name"
					clearable>
				</el-input>
			</div> -->
			
			<div class="search-container-fn-input" style="margin-left: auto;">
				<!-- <el-button type="primary" size="mini" @click="getList('rest')">查询</el-button> -->
				<el-button type="success" size="mini" @click="editItem('add')">新增</el-button>
			</div>
		</div>
		<el-table v-loading="listLoading" :data="tableData" row-key="id"
			:tree-props="{hasChildren: 'hasChildren'}" border style="width: 100%">

			<el-table-column align="left" width="200" prop="name" label="权限名称">
				<template slot-scope="{row}">

					<span style="margin-left:20px">{{row.name}}</span>
					<i v-if="row.p_id==0" class="el-icon-circle-plus icon-depart" @click="editItem('add',row)"></i>
					<!-- <i class="el-icon-caret-right icon-depart"></i> -->
				</template>
			</el-table-column>
			<el-table-column prop="path" label="路由" width="150">
			</el-table-column>
			<el-table-column prop="path" label="权限操作">
				<template slot-scope="{row}">
					<el-tag style="margin-right:10px;" type="success" v-for="item in row.operates_name" :key="item.id">{{ item }}</el-tag>
				</template>	
			</el-table-column>
			
			<el-table-column prop="created_at" label="创建时间" width="260" align="center">
			</el-table-column>
			<el-table-column prop="address" label="操作" width="200" align="center">
				<template slot-scope="{row}">
					<el-button style="padding: 7px 8px;" @click="editItem('edit',row)" type="primary" size="mini" icon="el-icon-edit-outline">修改</el-button>
					<el-button style="padding: 7px 8px;margin-left: 5px;" type="danger" size="mini" @click="delItem(row)" icon="el-icon-delete">删除</el-button>
				</template>
			</el-table-column>


		</el-table>
		<pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
			@pagination="getList" />
		<!--新增编辑 快捷回复-->
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="60%" v-if="dialogVisible">
			<el-form ref="ruleForm" :model="currInfo" label-width="80px" :rules="rules" class="demo-ruleForm">
				<el-form-item label="父级" prop="parent_id">
					<template v-if="openType=='add'">
						<el-select v-model="currInfo.parent_id" placeholder="请选择">
							<el-option key="0" label="顶级权限" value="0"></el-option>
							<el-option class="depart-class" v-for="item in top_auth" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</template>
					<template v-else>
						{{(currInfo.p_name)}}
					</template>
				</el-form-item>
				<el-form-item label="名称" prop="name">
					<el-input v-model="currInfo.name" maxlength="12" show-word-limit></el-input>
				</el-form-item>

				<el-form-item label="路由" prop="router">
					<template>
						<el-input v-model="currInfo.path" maxlength="50" show-word-limit></el-input>
					</template>
					
				</el-form-item>

				<el-form-item label="操作" v-if="currInfo.parent_id!=0">
					<el-checkbox-group v-model="checkList">
						<el-checkbox  :label="item.id" v-for="item in operates" :key="item.id">{{item.name}}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>

			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button type="success" size="mini" @click="saveReply('ruleForm')" >保存
				</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		authList,
		getTopPermissions,
		addpermission,
		editPermission,
		delPermission
	} from '@/api/cdm/auth.js'
	import {
		mapState
	} from "vuex";
	import Pagination from '@/components/Pagination' // secondary package based on el-pagination
	export default {
		name: "auth",
		components: {
			Pagination
		},
		mounted(){
			this.$store.dispatch('auth/getOperates');
			this.$store.dispatch('auth/getAgents')

		},
		computed: {
			...mapState({
				operates: state => state.auth.operates,
				agents: state => state.auth.agents
			}),
			dialogTitle() {
				return {
					'add': '添加权限',
					'edit': '编辑权限',
				} [this.openType] || '添加权限'
			}
		},
		created() {
			this.getTopPermissions()
			this.getList()
		},
		data() {
			return {
				checkList:[],
				allPermissions:[],
				// disabled: false,
				top_auth: [],
				roles: [],
				searchParams: {
					depart_name: ''
				},
				currInfo: {
					name: '',
					path: '',
					parent_id: 0
				},
				dialogVisible: false,
				replyContent: '',
				openType: '',
				total: 0,
				listLoading: false,
				listQuery: {
					page: 1,
					limit: 20,
					importance: undefined,
					title: undefined,
					type: undefined,
					sort: '+id'
				},
				tableData: [],
				ruleForm: {
					name: '',
					code: '',
					sort: ''
				},
				rules: {
					parent_id: [{
						required: true,
						message: '请选择父级角色',
						trigger: 'change'
					}, ],
					name: [{
							required: true,
							message: '请输入名称',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 15,
							message: '长度在 2 到 15 个字符',
							trigger: 'blur'
						}
					],
					path: [{
						required: true,
						message: '请输入路由',
						trigger: 'blur'
					}, ],
	

				},
			}
		},

		methods: {
			// getPermissions(){
			// 	getPermissions(res=>{
			// 		if(200 == res.code){
			// 			this.allPermissions = res.data
			// 		}
			// 	})
			// },
			getTopPermissions(){
				getTopPermissions().then(res=>{
					if(200 == res.code){
						this.top_auth = res.data
					}
				})
			},
			// getTopAuth() {
			// 	getTopAuth().then(res => {
			// 		this.top_auth = res.data
			// 		console.log('this.top_auth', this.top_auth)
			// 	})
			// },
			getList(type) {
				if (type == 'rest') {
					this.listQuery.page = 1
				}
				this.listLoading = true
				let params = {}
				params.page = this.listQuery.page
				params.page_size = this.listQuery.limit
				params.name = this.searchParams.name
				params.type = this.searchParams.type
				params.created_at = this.searchParams.created_at
				authList(params).then(response => {
					let data = response
					if (data.code == 200) {
						let _data = data.data
						this.total = _data.total
						this.listQuery.limit = _data.per_page
						this.tableData = _data.list
					}
					this.listLoading = false
				}).catch(error => {
					console.log(error);
					this.listLoading = false
				})
			},
			editItem(type, item) {
				this.openType = type
				
				if (type === 'add') {
					this.currInfo = {
						name: '',
						parent_id: '0',
						path: '',
						operates:[]
					}
					this.checkList = []
				}else if (type === 'edit') {
					if (!item) {
						this.$message({
							type: 'error',
							message: '请选择要修改的权限'
						});
						return
					}
					this.currInfo = Object.assign({},item)
					console.log('this.top_auth', item,this.top_auth,item.parent_id)
					if (item.parent_id == 0) {
						this.currInfo.p_name = '顶级权限'
					} else {
						let curAuth = this.top_auth.find(v => v.id == item.parent_id)
						this.currInfo.p_name = curAuth ? curAuth.name : ''
						this.checkList = item.operates_ids
					}

				}
				this.dialogVisible = true
			},
			delItem(item) {

				this.$confirm(`是否删除权限： "${item.name}"？`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'error'
				}).then(() => {
					delPermission(item.id).then(response => {
						let data = response
						if (data.code == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							this.getList()
							this.getTopAuth()
						} else {
							this.$message({
								type: 'error',
								message: data.msg ? data.msg : data.message
							});
						}

					}).catch(error => {
						console.log(error);
					})

				}).catch(() => {

				});
			},
			saveReply(form) {
				console.log('this.currInfo',this.currInfo)
				this.$refs[form].validate((valid) => {
					if (!valid) {
						return
					} else {
						// this.disabled = true
				
						let params = this.currInfo
						params.operates = this.checkList
						if (this.openType == 'add') {
							addpermission(params).then(res => {
								if (res.code == 200) {
									this.getTopPermissions()
									this.$message({
										type: 'success',
										message: this.dialogTitle + '成功！'
									});
									this.getList()
									this.dialogVisible = false
								} else {
									this.$message({
										type: 'error',
										message: res.msg ? res.msg : res.message
									});
								}
							}).catch(err => {
								// this.disabled = false
								this.$message({
									type: 'error',
									message: err.msg ? err.msg : err.message
								});
							})
						} else {
							let params = this.currInfo
							params.operates = this.checkList
							editPermission(this.currInfo.id,params).then(res => {
								if (res.code == 200) {
									this.$message({
										type: 'success',
										message: this.dialogTitle + '成功！'
									});
									this.getList()
									this.dialogVisible = false
								} else {
									this.$message({
										type: 'error',
										message: res.msg ? res.msg : res.message
									});
								}
							}).catch(err => {
								// this.disabled = false
								this.$message({
									type: 'error',
									message: err.msg ? err.msg : err.message
								});
							})
						}
					}
				});



			}
		}
	}
</script>
<style>
	.el-radio-group .el-radio-button__inner {
		border: 1px solid #DCDFE6;
	}
</style>
<style scoped lang="scss">
	.depart-class {
		padding-left: 35px;
	}

	.icon-depart {
		display: inline-block;
		margin: 0 10px;
		color: #409eff;
	}

	.dot {
		display: inline-block;
		border: 5px solid;
		border-radius: 50%;
		margin: 0 5px;
	}
</style>
